(function($, window, document, undefined) {
    $(document).ready(function() {
        var $cookie_domain = window.location.hostname.split('.').slice(-2).join('.');
        var $get_location = $('.get_location');
        $get_location.on('click', function(e) {
            e.preventDefault();
            var $form = $(this).parents('form'),
                $input = $form.find('input[name=place]');
            $(this).addClass('loading');
            get_location($input, $form);
        });
        // Location Form and filters
        var locationForm = document.querySelector( '.location_form' ),
        	$location_form = $( locationForm );
        $location_form.find('.filter').on('click', function() {
            var $checkbox = $(this).find('input');
            if ( $checkbox.prop("checked") ) {
                $(this).addClass('active');
            } else {
                $(this).removeClass('active');
            }
            locationForm.submit();
        });

        $location_form.on('submit', function(e) {
            var $zip = $(this).find('#zip');
            if ($zip && $zip.val() && isNaN($zip.val())) {
                e.preventDefault();
                getZipCode($zip, $location_form);
            }
        });

        var $cta_form = $('#gtmFindALocationCTA');
        $cta_form.on('submit', function(e) {
            var $zip = $cta_form.find('input[name=zip]');
            if ($zip && $zip.val() && isNaN($zip.val())) {
                e.preventDefault();
                getZipCode($zip, $cta_form);
            }
        });

        function getZipCode(input, form) {
            var value = input.val(),
                $url = 'https://maps.googleapis.com/maps/api/geocode/json?address=';

            if (value.indexOf(' oh') == -1 && value.indexOf(' Oh') == -1) {
                value = value + ' Ohio';
            }

            $.getJSON($url + encodeURIComponent(value), function(data) {

                //console.log(data.results[0]);
                if (!data.results[0].geometry.location.lat || !data.results[0].geometry.location.lng) {
                    return;
                }
                var latlng = new google.maps.LatLng(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng),
                    geocoder = new google.maps.Geocoder();

                geocoder.geocode({
                    'latLng': latlng
                }, function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            var j;
                            for (j = 0; j < results[0].address_components.length; j++) {
                                if (results[0].address_components[j].types[0] == 'postal_code') {
                                    input.val(results[0].address_components[j].short_name);
                                    setTimeout(function() {
                                        form.submit();
                                    }, 1000);
                                }
                            }
                        }
                    } else {
                        error();
                    }
                });
            });
        }

        if (!navigator.geolocation) {
            $get_location.attr('disabled', 'disabled');
            return;
        }

        function get_location($input_selector, $form) {
            var $input = $($input_selector),
                $latinput = $form.find('input[name=latitude]'),
                $lnginput = $form.find('input[name=longitude]');

            function success(position) {
                var latitude = position.coords.latitude;
                var longitude = position.coords.longitude;
                var latlng = new google.maps.LatLng(latitude, longitude);
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    'latLng': latlng
                }, function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            loop1: for (var i = 0; i < results.length; i++) {
                                loop2: for (var j = 0; j < results[i].address_components.length; j++) {
                                    if (results[i].address_components[j].types[0] == 'postal_code') {
                                        var zip = results[i].address_components[j].short_name;
                                        $input.val(zip);
                                        $latinput.val( latitude );
                                        $lnginput.val( longitude );
                                        set_location_cookies(latitude, longitude);
                                        $get_location.removeClass('loading');
                                        $form.submit();
                                        break loop1;
                                    }
                                }
                            }
                        }
                    } else {
                        $get_location.removeClass('loading').addClass('error');
                        error();
                    }
                });
            };

            function set_location_cookies(latitude, longitude) {
                Cookies('last_known_latitude', latitude, {
                    path: '/',
                    domain: $cookie_domain
                });
                Cookies('last_known_longitude', longitude, {
                    path: '/',
                    domain: $cookie_domain
                });
            }

            function error() {
                $input.attr("placeholder", "Unable to retrieve your location");
            };

            $input.attr("placeholder", "Locating…");
            navigator.geolocation.getCurrentPosition(success, error);
        }

        //Default Location
        var $default_location = $('.set_default_location');
        $default_location.on('click', function(e) {
            e.preventDefault();
            if ($(this).hasClass('default')) {
                return;
            }
            $default_location.removeClass('default').text('Set as Default Location');
            //console.log($(this).attr('data-locationID'));
            Cookies('default_site', $(this).attr('data-siteID'), {
                path: '/',
                domain: $cookie_domain
            });
            Cookies('default_location', $(this).attr('data-locationID'), {
                path: '/',
                domain: $cookie_domain
            });
            $(this).addClass('default').html('Default Location');
        });

        var $default_location_cookie = getCookie('default_location');
        if ($default_location_cookie) {
            $default_location.each(function() {
                if ($(this).data('locationid') == $default_location_cookie) {
                    $(this).addClass('default').text('Default Location');
                }
            });
        }

    });

})(jQuery, window, document);